export class Constants {

    static codeErreur = {
        type: {
            Internet: '225_1',
            TimeOut: '225_2',
            Technique: '225_3',
            Metier: '225_4'
        },
        message: {
            messageAttente: 'Veuillez patienter.\nNous procédons à des vérifications.\nCette opération peut prendre quelques minutes.',
            messageInternet: 'Veuillez vérifier votre connexion et réessayez ultérieurement.',
            messageTechnique: 'Service temporairement indisponible.\nVeuillez réessayer ultérieurement.',
            messageMetier: 'Veuillez renseigner tous les champs.',
            messageTimeout: 'Votre demande n\'a pas abouti.\nVeuillez vérifier votre connexion internet et réessayez ultérieurement.',
            messageConnexionTimeout: 'Votre tentative de connexion a échoué.\nVeuillez réessayer ultérieurement.',
            messageDashboardFail: 'La mise à jour de vos données a échoué.\nVeuillez réessayer ultérieurement.'
        }
    };
}
