import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { CustomExceptionMessage } from '../_helpers/custom-exception-message';
import { Cypher } from '../_helpers/cypher';


@Injectable({
    providedIn: 'root'
})

export class SendService {
    private apiBaseUrl = environment.apiBaseUrl;

    private timeout = 50000;
    constructor(private httpClient: HttpClient) { }

    /**
     * @param data - données à envoyer au serveur api
     * @param methodToCall - méthode à appeler
     */
    postData(data: any, methodToCall: any): any {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders().set('Content-Type', 'application/json');
            this.httpClient.post(`${this.apiBaseUrl}/${methodToCall}`, Cypher.encrypt(data), { headers }).pipe(timeout(this.timeout)).toPromise().then((res: any) => {
                if (environment.isCrypte) {
                    res = JSON.parse(JSON.stringify(Cypher.decrypt(res)));
                    resolve(res);
                } else {
                    resolve(res);
                }
            }, (err) => {
                if (err.status === 0) {
                    reject(CustomExceptionMessage.getExceptionInternet());
                } else if (err.status > 400 && err.status !== 504) {
                    reject(CustomExceptionMessage.getExceptionTechnique());
                } else {
                    reject(CustomExceptionMessage.getExceptionTimeout());
                }
            });
        });
    }
}
