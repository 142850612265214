import { Constants } from './constants';

export class CustomExceptionMessage {
    Text: string;
    Code: string;

    constructor(Text: string, Code: string = Constants.codeErreur.type.Metier) {
        this.Code = Code;
        this.Text = Text;
    }

    static getExceptionTechnique(): any {
        return new CustomExceptionMessage(Constants.codeErreur.message.messageTechnique, Constants.codeErreur.type.Technique);
    }

    static getExceptionTimeout(): any {
        return new CustomExceptionMessage(Constants.codeErreur.message.messageTimeout, Constants.codeErreur.type.TimeOut);
    }
    static getExceptionInternet(): any {
        return new CustomExceptionMessage(Constants.codeErreur.message.messageInternet, Constants.codeErreur.type.Internet);
    }
}
