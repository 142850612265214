export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBeNMVhVmhBPUpK7fB9y185
ecc93dAJkuGRHgFG3cHMdmrUc1BkB3e/Xb9htP8zzybNjdHpgGyw46yF+S1M0uEn
pRP2LYOyF4/hAcJFpP/NbUsMdNjDk9UDNgSxroRtVMTG4XMgE85G0/ZhO/3mJ96d
JjvXhr6PPDROVTzdvKJZPr2hTvJgFF1IoL85gKL8ux9NbI2/WwwCJFu+fP4w6/ZZ
PT2C/MAppSkvrZlQb9W4dU8FzWhytWk6up6geQWEc5i0rEmQSHYcnBJ9AYQvLiuE
AWOSg3T17P969FlxsXDlrJ0ekIa30uboTfzbn4DryFupfbJJLgWuYvy0QirpgIxf
AgMBAAE=
-----END PUBLIC KEY-----`;

export const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEAh4HZixI+C32uJ+Oz4H374/U/S/NwTzQUt19EjK07NI+mrGlA
qxtSeUiirGto0CWn0Fh7u2lT9rGLgiYHLuQPamBN/aa8WHI+VJ0pz7ttJ6aYvPvW
DphP33BaIyZFvDWRBkbLIDD5drNeux6dUS9lY7tClUKg85P571dP3BVuKGio0mkJ
9d1IzYuc6N81VWGsT2DaFy52K2ZxbMY0tLlNF5s0QWVnBXp6bNcGZs3yReCbqIQW
w478AzdAtdnC+/M1l4Y5j8oa18HcRmRJp8Ovqik7MSyi/e7wyocm/B3BDf8n0wmB
a5+kcT86hHuOOAw/B7jPiPk/LrN7NqBZFUxX2wIDAQABAoIBAA6bn/EPGWMxvEmO
kYCc9D+h2537c1sGeUk/WutzbVOAPO6PNip7+osamnbN6XGu7YVXVJdihmusviZc
pR+X9FV6Zk324I0EW9XRT4WWZfEFU6fj2jKeltW2jfEGGjRs7eJoVVN2FB6Ivaic
Z0cuPdbfhBEFKsa3aBU2K2TFZqeyYHF0E7tTgOAdSddwUcffmB1FDUInD8YI/w8p
YxhyyclrSwANLRFoANoEJNi05xobGCx1UhwP2RDG5wDlAaJb+Y5fgcTOk+8Tj5nq
u86LWMd5Z81PMZqIxu/oT79RCr4KKalHt03kE5UWlORFxV5WgMb/F0eeXfJzTFbj
shT2n3kCgYEAxJgtPd24P6loaBZPEArRb45sjF7UnUj4YnxAsLRXYDCKBFHsjUqR
iYds3juu3EyCnTCBeYExeycPG22a+e8ShfQmxWCxWisHJhxCTZXISpESueY2Y5au
RdEEK02HPRf9TDO0zfc0UlOLO8hUt+1hAbjFw90XSPTP7SfBF7VwOD8CgYEAsHQw
z7QE2gywBFma3P7a4byHEoE52rqdtDOTh3xrQ/e7efNzl5SI1idvV+55Ivts6aZf
gAo9JBNqkoByAJ8Zk/pxdR1df+90yi9pMkSGMwcb0lMUJQi878vYukFPmf0mI9KW
9By3gXgad9+sl+Qg7Sy/lPnoyivI23xDoJN4GWUCgYEAupovJy/S+SpVnaa/ONzr
2lV3lu1UJk7i5KhHxvIGlZi9CEiynZIP/lh675365cQBni4yVyBX1StH3aL1nRJd
pKQQQPz8mjeJO5VLIdVwHccB3U40hea6WC6DJI8cNNMWbEQP9AgWs6X03LPUJhi9
ISM/JCAxP+CPk+1ODLD9aYUCgYBbwV+scgDf3h8dc/bamk/5eE6IqdgKzGW7UeKS
Xnls04KSjKz8EooBSmpUwDYPocxlx1T7wNdATjRp0A74EcN8NowPCWEYaM1DYdoD
AK0EFds6yGa+R0fwLgEaAWJCQeg2UoiakeA+m9SvpwkZxs/0ZQOOlVGe55u/vmNw
OZ8HHQKBgQCFXKLlm5co1ilHYHw6Bu+sXQqpVZLchshR8eQhTNcDDzKBuB9ZLEb+
GKa87S+lh3yE51VS8Nvdx+2rgvH4gTyFz2pg1N5C+GuLgFCCd5FshjC59Mj4ankV
HMFwZ9Y8Xe4f7J7x/qIwJQEVOKaG8zeYotLVykEl7XnBoQPK62WZ6g==
-----END RSA PRIVATE KEY-----`;
