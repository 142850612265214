<div class="main">
    <section class="signup">
        <div class="col-md-8 offset-md-2">
            <div class="signup-content">
                <form class="signup-form" [formGroup]="inscriptionForm" (ngSubmit)="onSubmit()" novalidate>
                    <div class="row">
                        <div class="col-md-4 bloc-logo">
                            <img src="assets/images/logo-jubile.png" class="img-fluid logo"/>
                        </div>
                        <div class="col-md-8 titre">
                            <h2 class="form-title">S'incrire au wikado - EMUCI</h2>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-12">
                            <div class="reply-group contact-ok" *ngIf="success">
                                <p class="reply-group__title">C'est fait!</p>
                                <p class="reply-group__text small-text">Merci pour votre inscription a bien été prise en compte.</p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="reply-group contact-error" *ngIf="error">
                                <p class="reply-group__title">Ooops!</p>
                                <p class="reply-group__text small-text">Une erreur est survenue. Veuillez réessayer plus tard.</p>
                            </div>
                        </div> -->
                        <div class="col-md-12">
                            <div class="reply-group contact-error">
                                <p class="reply-group__text small-text" style="font-weight:bold">(*) Champs obligatoire</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" [ngClass]="{'is-invalid':!nom.valid && (nom.dirty || nom.touched)}" class="form-input" formControlName="nom" placeholder="Nom (*)" minlength="3" />
                                <div *ngIf="!nom.valid && (nom.dirty || nom.touched)">
                                    <div class="error-input" [hidden]="!nom.errors.required">Le nom est obligatoire</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-input" formControlName="prenom" placeholder="Prénoms" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="tel" [ngClass]="{'is-invalid':!telephone.valid && (telephone.dirty || telephone.touched)}" class="form-input" formControlName="telephone" placeholder="Téléphone (*)" maxlength="10" minlength="10" />
                                <div *ngIf="!telephone.valid && (telephone.dirty || telephone.touched)">
                                    <div class="error-input" [hidden]="!telephone.errors.required">Le numéro de téléphone est obligatoire</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="email" [ngClass]="{'is-invalid':!email.valid && (email.dirty || email.touched)}" class="form-input" formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="E-mail (*)" [email]="true"  minlength="5" />
                                <div *ngIf="!email.valid && (email.dirty || email.touched)">
                                    <div class="error-input" [hidden]="!email.errors.required">L'E-mail est obligatoire</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" [ngClass]="{'is-invalid':!naissance.valid && (naissance.dirty || naissance.touched)}" class="form-input" onfocus="(this.type='date')" formControlName="naissance" placeholder="Date de naissance (*)" minlength="10" />
                                <div *ngIf="!naissance.valid && (naissance.dirty || naissance.touched)">
                                    <div class="error-input" [hidden]="!naissance.errors.required">La date de naissance est obligatoire</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" [ngClass]="{'is-invalid':!lieu.valid && (lieu.dirty || lieu.touched)}" class="form-input" formControlName="lieu" placeholder="Lieu de résidence (*)" minlength="3" />
                                <div *ngIf="!lieu.valid && (lieu.dirty || lieu.touched)">
                                    <div class="error-input" [hidden]="!lieu.errors.required">Le lieu de résidence est obligatoire</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" class="form-input" formControlName="eglise" placeholder="Eglise fréquentée" />
                            </div>
                            <div class="col-md-6">
                                <input type="tel" [ngClass]="{'is-invalid':!parents.valid && (parents.dirty || parents.touched)}" class="form-input" formControlName="parents" placeholder="Contact d'un parent (*)" maxlength="10" minlength="10" />
                                <div *ngIf="!parents.valid && (parents.dirty || parents.touched)">
                                    <div class="error-input" [hidden]="!parents.errors.required">Le contact d'un parent est obligatoire</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" [disabled]="disableButton" class="btn btn-primary form-submit" >S'inscrire</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>