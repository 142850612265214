import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HomeService } from 'src/app/_services/home.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    inscriptionForm: any;
    success = false;
    error = false;
    closeResult: string = '';
    disableButton = false;
    emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    constructor(private fB: FormBuilder, private homeService: HomeService) {
        this.inscriptionForm = this.fB.group({
            nom: ['', [Validators.required]],
            prenom: [''],
            telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailRegex)]],
            naissance: ['', [Validators.required]],
            lieu: ['', [Validators.required]],
            eglise: [''],
            parents: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
        })
    }

    ngOnInit(): void {
    }

    get nom() {
        return this.inscriptionForm.get('nom')
    }

    get telephone() {
        return this.inscriptionForm.get('telephone')
    }

    get email() {
        return this.inscriptionForm.get('email')
    }

    get naissance() {
        return this.inscriptionForm.get('naissance')
    }

    get lieu() {
        return this.inscriptionForm.get('lieu')
    }

    get parents() {
        return this.inscriptionForm.get('parents')
    }

    onSubmit(): any {
        if (!this.inscriptionForm.valid) {
            Swal.fire({
                text: 'Veillez renseigner tous les champs obligatoire svp',
                icon: 'error'
            });
            return false;
        } else {
            this.disableButton = true;
            const { nom, prenom, telephone, email, lieu, naissance, parents, eglise } = this.inscriptionForm.value;
            const toSend = {
                nom,
                prenom,
                telephone,
                naissance,
                email: email.trim(),
                parents,
                lieu,
                eglise
            };
            this.homeService.postAddForm(toSend).then(retour => {
                if (retour.error) {
                    Swal.fire({
                        text: 'Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.',
                        icon: 'error'
                    });
                } else {
                    Swal.fire({
                        text: 'Merci pour votre inscription a bien été prise en compte.',
                        icon: 'success'
                    });
                    this.inscriptionForm.reset();
                    this.disableButton = false;
                }
            }).catch(err => {
                console.log(err);
                this.disableButton = false;
            })
        }
    }

}
