import { Injectable } from '@angular/core';
import { SendService } from './send.service';

@Injectable({
    providedIn: 'root'
})

export class HomeService {

    constructor(private sendService: SendService) { }

    postAddForm(data: any): Promise<any> {
        const contact = {
            nom: data.nom,
            prenom: data.prenom,
            telephone: data.telephone,
            email: data.email,
            naissance: data.naissance,
            lieu: data.lieu,
            eglise: data.eglise,
            parents: data.parents
        };
        return this.sendService.postData(contact, 'wikado/ajouter');
    }
}
