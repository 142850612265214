import { JSEncrypt } from 'jsencrypt';
import { privateKey, publicKey } from './keys/keys';

export class Cypher {

    static encrypt(data: any): string[] {
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);
        let dataString: any = '';
        if (typeof (data) === 'object') {
            dataString = JSON.stringify(data);
        } else if (typeof (data) === 'number') {
            dataString = data.toString();
        } else {
            dataString = data;
        }
        // secure data
        const dataEncrypted: any = dataString.match(/.{1,190}/g).map((str: any) => jsEncrypt.encrypt(str));
        return dataEncrypted;
    }

    static decrypt(cryptData: string[]): string | null {
        if (cryptData == null) {
            return null;
        }

        const jsEncrypt = new JSEncrypt();
        let plainText = '';
        let result: any;
        let finalResult = '';

        jsEncrypt.setPrivateKey(privateKey);
        cryptData.forEach(valuex => {
            result = jsEncrypt.decrypt(valuex);
            if (result === false) {
                throw new Error('Erreur lors du décryptage');
            }
            plainText += result;
        });

        try {
            finalResult = JSON.parse(plainText);
        } catch (error) {
            finalResult = plainText;
        }

        return finalResult;
    }
}
